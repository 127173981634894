<template>
  <div class="" v-show="tabledata">
    <div class="table-responsive desk inside-box" ref="tableContainer" v-if="isDesktop" @scroll="handleScroll('table')">
      <table class="table vtable eyer2">
        <thead>
          <tr>
            <th v-show="!column.hide" :class="column.align" :width="column.width" v-for="(column, index) in tabledata.columns" v-html="column.name" :key="index"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in tabledata.items">
            <tr @click="selectRow(item,  $event)" :class="`tr_${item.id}`">
              <td :class="[column.align, column.type != undefined ? `${column.type}-line` : '']"
                v-for="(column, indexColumn) in tabledata.columns" :key="indexColumn">
                {{tabledata.totalCount}}
                <slot v-if="column.key == 'name'" :name="`column_${column.key}`" :item="item"/>
                <slot v-if="column.type == 'custom'" :name="`column_${column.key}`" :item="item"/>
                <span v-if="column.type == 'date'">{{ formatDate(item, column.value) }}</span>
                <span v-if="column.type == undefined || column.type == 'string' && column.key != 'name'" v-html="getValue(item, column.value)"></span>
              </td>
            </tr>
          </template>
          <tr v-if="shouldShowLoadBox && isExamRoute">
            <td class="load-box" :colspan="tabledata.columns.length">
              <button class="btn btn-outline-secondary btn-load" type="button" name="button" @click="$emit('load-more-months')">{{ $t('exam.loadMore') }}</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="vtable-mob mob inside-box-mob" ref="tableResponsiveContainer" v-else @scroll="handleScroll('responsive')">
      <ul class="">
        <template v-for="(item, index) in tabledata.items">
          <li class="inside-box-mob-item" :class="`tr_${item.id}`" @click="selectRow(item, $event)">
            <p :class="`${column.type}-line`" v-for="(column, indexColumn) in tabledata.columns" :key="indexColumn">
              <!-- <slot v-html="column.name"></slot> -->
              <!-- <span v-html="getValue(item, column.value)"></span> -->
              <slot v-if="column.key == 'name'" :name="`column_${column.key}`" :item="item"/>
              <slot v-if="column.type == 'custom'" :name="`column_${column.key}`" :item="item"/>
              <slot v-if="column.type == 'date'" :item="item" @click="selectRow(item, $event)">{{ formatDate(item, column.value) }}</slot>
              <slot v-if="column.type == undefined || column.type == 'string' && !column.key" :item="item">{{ getValue(item, column.value) }}</slot>
              <!-- <span v-if="column.type == undefined || column.type == 'string' && column.key != 'name'" v-html="getValue(item, column.value)"></span> -->
            </p>
          </li>
        </template>
        <li v-if="shouldShowLoadBox && isExamRoute">
          <button class="btn btn-outline-secondary btn-load" type="button" name="button" @click="$emit('load-more-months')">{{ $t('exam.loadMore') }}</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  .scrollbar {
    overflow-y:                     auto;
  }

  .mob {
    display:                        none;
  }

  .btn-load {
    color:                          var(--Brand-Primary);
    display:                        block;
    margin:                         0 auto;
    padding:                        10px 20px;
    background:                     var(--Shades-BG);
    border:                         1px solid var(--Shades-30-Border);
  }

  .vtable-mob:not(.inside-box-mob) {
    ul {
      margin:                       0.5em 0;
      li {
        p {
          display:                  block;
          margin-bottom:            10px;
          // span:last-child:not(:first-child) {
          span:not(:first-child), button {
            float:                  right;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .desk {
      display:                      none;
    }
    .mob {
      display:                      block;
    }
    .vtable-mob .label {
      line-height:                  1.4;
    }
    .eyerweb {
      .vtable-mob {
        > ul > li p {
          &:first-child {
            margin-bottom:          25px;
          }
        }
      }
    }
  }
</style>

<script>
import { useExamFilterStore } from '@/store/examFilter'

export default {
  name: 'BaseTable',
  props: ['tabledata'],
  inject: ['scrollToTop'],
  data: () => ({
    dateFormat: "",
    isDesktop: true,
    previousScrollHeight: null,
  }),
  watch: {
    'tabledata.items'(newItems, oldItems) {
      if (newItems.length !== oldItems.length && this.isExamRoute) {
        const examFilterStore = useExamFilterStore()

        if (examFilterStore.filter.examCurrentPage > 1) {
          this.$nextTick(() => {
            const tableContainer = this.$refs.tableContainer;

            if (tableContainer && tableContainer.scrollHeight > tableContainer.clientHeight) {
              tableContainer.scrollTop = 5;
            }
          });
        }
      }
    },
  },
  computed: {
    shouldShowLoadBox() {
      return this.tabledata.items && Number(this.tabledata.totalLoaded > 0) && (Number(this.tabledata.totalLoaded) >= Number(this.tabledata.total))
    },
    isExamRoute() {
      return this.$router.currentRoute._value.name == 'exam-list'
    }
  },
  mounted: function() {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    })

    console.log('this.datable', this.tabledata.total);

    this.dateFormat = this.$i18n.t('dateTimeFormat')

    this.updateIsDesktop()
    window.addEventListener('resize', this.updateIsDesktop)

    this.scrollToTop(() => {
      const container = this.isDesktop ? this.$refs.tableContainer : this.$refs.tableResponsiveContainer
      if (container) {
        container.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      }
    })

    const examFilterStore = useExamFilterStore();
    if (examFilterStore.filter.examCurrentPage > 1) {
      this.$nextTick(() => {
        this.handleScrollHeight()
      })
    }
  },
  methods: {
    getValue(data, key) {
      return _.get(data, key)
    },
    formatDate(data, key) {
      let value = _.get(data, key)
      let result = moment(value).format(this.dateFormat)
      return result
    },
    selectRow(item, event) {
      // const examFilterStore = useExamFilterStore()
      //
      // if (examFilterStore.filter.examCurrentPage > 1)
      //   examFilterStore.filter.scrollPosition = this.$refs.tableContainer.scrollTop
      let isCommandPressed = event.ctrlKey || (event.button === 1)

      this.$emit('item-opened',  {item: item, commandPressed: isCommandPressed})
    },
    updateIsDesktop() {
      this.isDesktop = window.innerWidth > 991;
    },
    handleScroll(container) {
      const examFilterStore = useExamFilterStore()
      const tableContainer = container == 'table' ? this.$refs.tableContainer : this.$refs.tableResponsiveContainer;
      const scrollTop = tableContainer.scrollTop;
      const scrollHeight = tableContainer.scrollHeight;
      const clientHeight = tableContainer.clientHeight;

      if (scrollHeight - scrollTop - clientHeight < clientHeight * 0.1) {
        console.log('ta entrando no if de load more');
        this.debounce()
      }

      if (scrollTop <= 1 && examFilterStore.filter.examCurrentPage > 1) {
        console.log('entrou no scroll top');
        this.debounceTop();

        this.$nextTick(() => {
          this.handleScrollHeight()
        })
      }
    },
    handleScrollHeight() {
      const tableContainer = this.$refs.tableContainer;
      if (!tableContainer || !this.isExamRoute) return;

      const previousScrollHeight = this.previousScrollHeight || tableContainer.scrollHeight

      this.$nextTick(() => {
        const newScrollHeight = tableContainer.scrollHeight
        const heightDifference = newScrollHeight - previousScrollHeight

        if (heightDifference > 0) {
          tableContainer.scrollTop += heightDifference
        }

        this.previousScrollHeight = newScrollHeight

        console.log('Previous scroll height:', previousScrollHeight)
        console.log('New scroll height:', newScrollHeight)
        console.log('Height difference:', heightDifference)
      });
    },
    debounce: _.debounce(function() {
      console.log('ta chamando o debounce bottom');
      this.$emit('loadmore');
    }, 100, { 'maxWait': 100 }),
    debounceTop: _.debounce(function() {
      this.$emit('loadmoretop');
    }, 100, { 'maxWait': 100 }),
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateIsDesktop);
  }
}
</script>

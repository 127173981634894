import eyerCloud from '@/config/eyercloud'

class PatientService {

  async getList(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/list`, params)
    return response.data
  }

  async getPatient(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/get`, params)
    return response.data
  }

  async save(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/save`, params)
    return response.data
  }

  async updatePatient(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/update`, params)
    return response.data
  }

  async deletePatient(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/remove`, params)
    return response.data
  }

  async getExamList(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/exam/list`, params)
    return response.data
  }

  async getExamListWithImages(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/exam/list-with-examdata`, params)
    return response.data
  }

  async saveExam(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/exam/save`, params)
    return response.data
  }

  /**
   *
   * @param id the patient id
   * @returns
   */
  async getAccessInfo(id) {
    let params = {id: id}
    let response = await eyerCloud.post(`/api/v2/eyercloud/external/access-info`, params)
    return response.data
  }

  async searchPatient(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/list-partial`, params)
    return response.data
  }
  
  async searchPatientExams(params) {
    let response = await eyerCloud.post(`/api/v2/eyercloud/patient/list-partial-exams`, params)
    return response.data
  }

}
const patientService = new PatientService();
export default patientService;

<template>
  <div class="offcanvas offcanvas-end" tabindex="-1" :id="offcanvas_id" data-bs-backdrop="static" :aria-labelledby="`${offcanvas_id}_label`">
    <div class="offcanvas-header">
      <button class="btn-back" type="button" name="button" data-test="btnCloseOffcanvas" data-bs-dismiss="offcanvas" aria-label="Close" @click="doCancel">
        <IconArrowBack/>
        {{ $t('goBack') }}
      </button>
      <h5 class="offcanvas-title d-block" :id="`${offcanvas_id}_label`">{{ title }}</h5>
    </div>
    <div class="offcanvas-body">
      <p><b>{{ $t('reportExam.note') }}</b> {{ $t('reportExam.informative') }}</p>
      <div class="form-floating v-select-floating">
        <v-select
          id="reportDate"
          class="v-select form v-select-offcanvas"
          :placeholder="$t('reportExam.monthyear')"
          v-model="reportSelectedValue"
          data-test="vSelectReportDateOptions"
          :reduce="x => x.value"
          :options="reportDateOptions">
        </v-select>
        <label for="reportDate">{{ $t('reportExam.monthyear') }}</label>
      </div>
    </div>
    <div class="offcanvas-footer">
      <button type="button" class="btn" :class="okButtonClass" data-test="btnConfirm" @click="getReportsForClinic()">{{ $t('reportExam.generate') }}</button>
      <!-- <button type="button" class="btn" :class="cancelButtonClass" data-bs-dismiss="offcanvas" v-show="cancelButton" data-test="btnCancel" @click="doCancel">{{ cancelButton }}</button> -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.offcanvas {
  // width:                                510px !important;
  // padding:                              40px;

  .offcanvas-header,
  .offcanvas-body {
    padding:                            0;
  }

  .offcanvas-header {
    display:                            block;
  }

  .offcanvas-title,
  h3 {
    color:                              var(--Brand-Primary, #1B294A);
    font-size:                          38px;
    font-weight:                        300;
    line-height:                        normal;
    margin:                             26px 0;
  }

  h3 {
    font-size:                          var(--font-size-18);
  }

  .offcanvas-body {
    p,
    li {
      color:                            var(--Brand-Primary, #1B294A);
      font-size:                        var(--font-size-14);
      font-weight:                      300;
      line-height:                      140%; /* 19.6px */
      margin-bottom:                    26px;
    }

    .download-dates {
      display:                          flex;
      flex-direction:                   column;
      gap:                              19px;
    }
  }

  .btn {
    font-size:                          var(--font-size-14);
    font-weight:                        700;
    line-height:                        normal;
    letter-spacing:                     4.2px;
    text-align:                         center;
    text-transform:                     uppercase;
    width:                              100%;
    padding:                            20px;

    &.btn-outline-secondary {
      padding:                          20px 18px;
    }
  }

  .offcanvas-footer {
    display:                            flex;
    flex-direction:                     column;
    gap:                                26px;

    .btn-success {
      color:                            var(--Shades-W, #FFF);
      // margin-top:                       26px;
    }
  }
}
</style>

<script>
import { ref } from 'vue'
import { useUserStore } from '@/store/user'
import { enUS, ptBR, es } from 'date-fns/locale'

export default {
  name: 'ExamDownloadOffcanvas',
  props: ['offcanvas_id'],
  data: () => ({
    alert: {
      message: null,
      class: null
    },
    title: null,
    text: null,
    okButton: null,
    okButtonClass: null,
    clinicID: null,
    offcanvas_export: null,
    reportDateOptions: [],
    reportSelectedValue: null,
    session: {
      collaborator: {},
      currentClinic: {}
    },
  }),
  computed: {
    locale() {
      return this.$utils.datePickerLocale()
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
  },
  methods: {
    show(options = {}) {
      if (options.title)
        this.title = options.title

      if (options.text)
        this.text = options.text

      if (options.okButton) {
        this.okButton = options.okButton
        this.okButtonClass = options.okButtonClass
      }

      if (options.clinicID) {
        this.clinicID = options.clinicID
        this.fetchData()
      }

      this.offcanvas_export = new bootstrap.Offcanvas(document.getElementById(this.offcanvas_id)).show();

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve
        this.rejectPromise = reject
      })
    },

    doConfirm() {
      // this.offcanvas_export = null;
      this.doDownload()
      this.resolvePromise(true)
    },

    doCancel() {
      this.loadingDownload = false
      this.downloadReadyUrl = null
      this.offcanvas_export = null;
      this.resolvePromise(false)
    },

    fetchData: async function() {
      let params = {
        id: this.clinicID
      }
      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.getClinic(params);
        let clinic = response.clinic
        this.reportDateOptions = this.generateMonthYearList(clinic.createdAt)

        this.systemConfiguration = this.$clinicService.getSystemConfiguration(clinic)
      } catch (err) {
        this.$message.popup(this, `Error: ${err.message}`, 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },

    async getReportsForClinic(){
      if (!this.reportSelectedValue){
        // need to alert it to select a date.
        this.$message.popup(this, 'reportExam.invalidDate', 'operationWarning', 'warn')
        return
      }

      let params = {
        clinicId: this.clinicID, // Phelcom BRASIL
        date: this.reportSelectedValue.date
      }
      this.loading = true
      NProgress.start()
      try {
        let response = await this.$clinicService.generateClinicReportsByDate(params)
        let downloadURL = response.downloadURL
        window.open(downloadURL)
        this.$message.popup(this, 'reportExam.successReport', 'operationSuccess', 'success')
      } catch (err) {
        this.$message.popup(this, `Error: ${err.message}`, 'operationFail', 'error')
      }
      NProgress.done(true)
      this.loading = false
    },

    generateMonthYearList(createdAt) {
      const startDate = new Date(createdAt);
      const currentDate = new Date();

      const startMonth = startDate.getMonth() + 1; // getMonth is 0-indexed
      const startYear = startDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentYear = currentDate.getFullYear();
      const totalMonths = (currentYear - startYear) * 12 + (currentMonth - startMonth + 1);
      return Array.from({ length: totalMonths }, (_, index) => {
        const month = (startMonth + index - 1) % 12 + 1;
        const year = startYear + Math.floor((startMonth + index - 1) / 12);
        return {
            value: {
                date:{
                    month: month.toString().padStart(2, '0'),
                    year: year.toString()
                }
            },
            label: `${month.toString().padStart(2, '0')}/${year}`
          };
      });
    }
  }
}
</script>

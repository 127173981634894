<template>
  <header class="flex-between">
    <!-- <div class="">
      <button id="btnCollapseMenu" ref="btnCollapseMenu" class="btn-icon" type="button" name="button" data-test="btnCollapse" @click="$emit('docollapse')">
        <IconHamburger/>
      </button>
    </div> -->

    <div class="">
        <!-- remove v-if="!isPaid" -->
      <!-- <img class="logo" src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo white" width="152">  -->
      <div class="clinic-box">
        <div class="">
          <h1 v-if="session.currentClinic">
            {{ session.currentClinic.name }}
          </h1>

          <button class="btn-icon" type="button" name="button" data-test="btnChangeClinic" @click="openChangeOffcanvas">
            <IconChange/>
          </button>
        </div>

        <div class="">
          <p>
            powered by
          </p>
          <!-- <img class="logo" src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo white" width="52"> -->
          <img class="logo dark-logo" src="@/assets/images/phelcom/eyercloud_logo.svg" alt="Eyercloud logo" width="52">
          <img class="logo light-logo" src="@/assets/images/phelcom/eyercloud_logo_white.svg" alt="Eyercloud logo" width="52">
        </div>

      </div>

      <div class="search-box" :class="{'open': patientList.length || isExamFilter}">
        <div class="form-group">
          <IconSearchFilter/>
          <!-- <input class="form-control" type="text" name="search" v-model="searchValue" v-if="!isExamFilter"
                 :placeholder="isExamFilter ? $t('patientSearch.searchPatientExam') : $t('patientSearch.search')" @keypress.enter="doSearchPatientsExams"> -->
            <input class="form-control" type="text" name="search" v-model="searchValue" v-if="!isExamFilter"
                 :placeholder="isExamFilter ? $t('patientSearch.searchPatientExam') : $t('patientSearch.search')" @keyup="doSearch">
          <p class="no-margin" v-else>{{ $t('exam.filterExams') }}</p>
          <div v-if="isExamRoute">
            <IconFilter v-if="!isExamFilter" @click="toggleExamFilter"/>
            <IconClose v-else @click="toggleExamFilter"/>
          </div>
        </div>
        <div class="list-group-box" v-if="patientList.length">
          <ul class="list-group scrollbar">
            <li class="list-group-item" v-for="(item, index) in patientList" @click="toPatientDetails(item)">{{ item.fullName }}</li>
          </ul>
        </div>

        <!-- <div class="list-group-box" v-if="patientList.length">
          <ul class="list-group scrollbar">
            <li class="list-group-item" v-for="item in patientList" @click="toPatientDetails(item)">
              {{ item.fullName }}
              <ul class="list-group">
                <li class="list-group-item" v-for="exam in item.exams" @click="toExamDetails($event, exam)">
                  {{ `${$t('exam.examCarriedOutOn')} ${formatDate(exam.date)}` }}
                </li>
              </ul>
            </li>
          </ul>
        </div> -->

        <div class="exam-filter-box" v-show="isExamFilter" id="examFilterBox">
          <ExamFilter v-if="isExamRoute" @close="toggleExamFilter"/>
        </div>
      </div>
    </div>

    <!-- ajustar o teleport para cabeçalhos das paginas -->
    <!-- <div id="homeHeaderTeleportBox"></div> -->

    <div class="">
      <!-- <div class="storage-box flex-center">
        <p>plano gratuito</p>
        <MenuStorageBar/>
        <button class="btn btn-outline-secondary" type="button" name="button">
          upgrade
          <IconArrowUp/>
        </button>
      </div> -->

      <MenuProfile></MenuProfile>

      <!-- <a class="btn-icon flex-center" data-test="anchorMenuDesktopSettings" @click="settingsMenu()">
        <IconSettings/>
      </a> -->

      <a class="btn btn-outline-secondary" :href="helpcenterUrl" target="_blank">{{ $t('menu.help') }}</a>
    </div>
    <teleport to="body">
      <ClinicChangeOffcanvas :offcanvas_id="'ClinicChangeOffcanvas'" ref="clinicChangeOffcanvas"/>
    </teleport>
  </header>
</template>

<style lang="scss" scoped>
header {
  position:                       relative;
  align-self:                     stretch;
  height:                         62px;
  width:                          100%;
  padding-right:                  20px;
  padding-left:                   20px;
  border-bottom:                  1px solid var(--Shades-20-Border);
  z-index:                        1039;

  h1 {
    font-size:                    var(--font-size-22);
    font-weight:                  500;
    line-height:                  normal;
    letter-spacing:               1.1px;
    white-space:                  nowrap;
    text-overflow:                ellipsis;
    overflow:                     hidden;
    background:                   linear-gradient(90deg, #1B294A 0.02%, #3CB391 99.98%);
    background-clip:              text;
    -webkit-background-clip:      text;
    -webkit-text-fill-color:      transparent;
    width:                        100%;
    max-width:                    600px;
    margin-bottom:                5px;
  }

  p {
    color:                        var(--Shades-40);
    font-size:                    var(--font-size-8);
    font-weight:                  500;
    line-height:                  normal;
    letter-spacing:               1.12px;
    text-transform:               uppercase;
    text-overflow:                ellipsis;
    overflow:                     hidden;
    margin:                       0 6px 0 0;
  }

  > div {
    display:                      flex;
    justify-content:              flex-end;
    align-items:                  center;

    &:first-child {
      display:                    flex;
      flex-direction:             row;
      justify-content:            space-between;
      // justify-content:            flex-start;
      gap:                        30px;
      width:                      100%;

      .clinic-box {
        display:                  flex;
        flex-wrap:                wrap;
        flex-direction:           column;
        justify-content:          flex-start;
        align-items:              flex-start;
        width:                    auto;

        > div {
          display:                flex;
        }

        .btn-icon {
          padding:                4px !important;
          display:                flex;
          align-items:            center;
          justify-content:        center;
          width:                  30px;
          height:                 30px;
          // padding:                unset;
          background:             var(--Shades-W);
          border:                 1px solid var(--Shades-30-Border);

          svg {
            width:                20px;
            height:               20px;
          }
        }
      }

      .btn-icon {
        font-size:                14px;
        background-color:         #727272;
        padding:                  3px 6px;
        width:                    30px;
        height:                   30px;
        border:                   unset;
        line-height:              0;
        svg {
          width:                  22px;
        }
      }
    }

    &:last-child {
      .btn-outline-secondary {
        margin-left:              15px;
      }
    }
  }

  .btn-icon,
  .btn-outline-secondary {
    height:                       36px;
  }

  .btn-icon {
    @include                       border_radius(50%);
    width:                         36px;
    padding:                       0;
    margin:                        0 10px;
    border:                        1px solid var(--Shades-30-Border);
    &:hover svg,
    &:focus svg {
      stroke:                      inherit;
    }
  }

  .btn-outline-secondary {
    margin:                        0;
  }

  .logo {
    visibility:                    hidden;
  }

  .search-box {
    // @include                       border_radius(28px);
    position:                      relative;
    width:                         100%;
    // max-width:                     424px;
    background:                    var(--Shades-W);
    // border:                        1px solid var(--Shades-20-Border);
    top:                           0;

    .form-group {
      display:                     flex;
      flex-direction:              row;
      align-items:                 center;
      justify-content:             space-between;
      gap:                         10px;
      width:                       100%;
      height:                      61px;
      margin:                      0;
      padding:                     0 20px 0 13px;
    }

    .form-control {
      color:                       var(--Shades-50);
      font-size:                   var(--font-size-14);
      font-weight:                 400;
      line-height:                 normal;
      letter-spacing:              0.7px;
      text-overflow:               ellipsis;
      overflow:                    hidden;
      width:                       calc(100% - 35px) !important;
      height:                      61px;
      padding:                     0;
      background:                  var(--Shades-W);
      border:                      0;

      &:focus {
        @include                   box_shadow(unset);
        outline:                   unset;
      }
    }

    .exam-filter-box {
      @include                     border_radius(0 0 28px 28px);
      @include                     box_shadow(0px 4px 4px 0px rgba(0, 0, 0, 0.25));
      position:                    absolute;
      width:                       100.3%;
      height:                      100%;
      max-height:                  0px;
      background:                  var(--Shades-BG);
      border:                      1px solid var(--Shades-20-Border);
      border-top:                  0;
      visibility:                  hidden;
    }

    p {
      color:                       var(--Shades-50);
      font-size:                   var(--font-size-14);
      font-weight:                 400;
      line-height:                 normal;
      letter-spacing:              0.7px;
      text-transform:              capitalize;
      text-align:                  left;
      width:                       100%;
    }

    .list-group-box {
      @include                     border_radius(0 0 28px 28px);
      @include                     box_shadow(0px 4px 4px 0px rgba(0, 0, 0, 0.25));
      position:                    absolute;
      width:                       100.3%;
      height:                      100%;
      max-height:                  0px;
      padding:                     40px 10px 20px 20px;
      background:                  var(--Shades-W);
      border:                      1px solid var(--Shades-20-Border);
      border-top:                  0;
      visibility:                  hidden;
    }

    ul {
      list-style-type:             none;
      height:                      100%;
      padding-right:               10px;
      overflow-y:                  auto;

      li {
        @include                   border_radius(0);
        color:                     var(--Shades-70);
        font-size:                 var(--font-size-14);
        font-weight:               400;
        line-height:               normal;
        letter-spacing:            0.7px;
        margin-bottom:             17px;
        background:                var(--Shades-W);
        border-bottom:             1px solid var(--Shades-30-Border);
        border-top:                0;
        border-right:              0;
        border-left:               0;
        cursor:                    pointer;

        li {
          margin:                  0;
          border:                  0;

          &:first-child {
            margin-top:            5px;
          }
        }
      }
    }

    &.open {
      // @include                     border_radius(28px 28px 0 0);
      @include                     box_shadow(0px 4px 4px 0px rgba(0, 0, 0, 0.25));
      border-bottom:               0;

      .list-group-box {
        // display:                   block;
        max-height:                282px;
        top:                       42px;
        left:                      -1px;
        visibility:                visible;

        ul {
          max-height:              220px;
        }

        &,
        ul {
          height:                  auto;
        }
      }

      .exam-filter-box {
        // height:                    371px;
        // max-height:                371px;
        height:                    193px;
        max-height:                193px;
        top:                       42px;
        left:                      -1px;
        visibility:                visible;
      }
    }
  }
}

.dark-theme {
  header {
    h1 {
      background:                  linear-gradient(90deg, #A8FFEF 0.02%, #3CB391 99.98%);
      background-clip:             text;
      -webkit-background-clip:     text;
      -webkit-text-fill-color:     transparent;
    }
  }
}

.retract {
  header {
    width:                         100%;

    .logo {
      visibility:                  visible;
    }
  }
}

.storage-box {
  @include                        border_radius(30px);
  display:                        none;
  flex-direction:                 row;
  gap:                            10px;
  height:                         54px;
  padding:                        11px 10px;
  margin-right:                   10px;
  border:                         1px solid var(--Shades-30-Border);
  p {
    @include                      border_radius(99px);
    color:                        var(--Brand-Secondary);
    text-align:                   center;
    font-size:                    var(--font-size-10);
    font-weight:                  500;
    line-height:                  normal;
    letter-spacing:               2.15px;
    text-transform:               uppercase;
    max-width:                    84px;
    padding:                      5px 10px;
    margin:                       0;
    border:                       1px solid var(--Brand-Secondary);
  }

  .progress-box {
    width:                        103px;
    margin:                       0;
  }

  .btn-outline-secondary {
    @include                      border_radius(0);
    gap:                          10px;
    border:                       0;
    border-left:                  1px solid var(--Shades-30-Border);
    padding-left:                 10px;
    padding-right:                0;

    svg {
      @include                    border_radius(50%);
      width:                      30px;
      height:                     30px;
      padding:                    8px;
      background-color:           #8F8F8F;
    }
  }
}

@media screen and (min-width: 992px) and (max-width: 1365px) {
  header {
  > div {
      &:first-child {
        gap:                      10px;
      }

      .clinic-box {
        max-width:                400px;

        h1 {
          white-space:            nowrap;
        }
      }
    }
  }
}

@media (min-width: 1650px) {
  header .search-box {
    .exam-filter-box,
    .list-group-box {
      width:                      100.1%;
    }
  }
}
</style>

<script>
import { useUserStore } from '@/store/user'

import ClinicChangeOffcanvas from './clinic/ClinicChangeOffcanvas.vue'
import ExamFilter from './exam/ExamFilter.vue'
import MenuProfile from './menu/MenuProfile.vue'
import MenuStorageBar from './menu/MenuStorageBar.vue'

export default {
  name: 'HomeHeader',
  components: {
    ClinicChangeOffcanvas,
    ExamFilter,
    MenuProfile,
    MenuStorageBar
  },
  data: () => ({
    session: {
      collaborator: {},
      currentClinic: {}
    },
    isPaid: true,
    searchValue: '',
    patientList: {},
    isExamFilter: false,
    locale: null,
    isExamRoute: false,
  }),
  watch: {
    $route(to, from) {
      if (to.name == 'exam-list')
        this.isExamRoute = true
      else
        this.isExamRoute = false
    },
  },
  computed: {
    helpcenterUrl() {
      if (this.locale == 'pt-br')
        return 'https://phelcom.com/pt-br/helpcenter/'

      return 'https://phelcom.com/helpcenter/'
    }
  },
  mounted() {
    const userStore = useUserStore()
    this.session = userStore.sessionInfo
    this.locale = this.session.currentClinic.locale.toLowerCase()

    // fazer watch na rota
    // console.log('this.$router.currentRoute', this.$router.currentRoute);
    // this.isExamRoute = this.$router.currentRoute._value.path == '/exam/' || this.$router.currentRoute._value.path == '/exam'
    this.isExamRoute = this.$router.currentRoute._value.name == 'exam-list'
  },
  methods: {
    // settingsMenu: async function() {
    //   this.$router.push({name: 'proxy-router', params: {
    //     target: 'settings',
    //     data: JSON.stringify({})
    //   }})
    // },
    async doSearch() {
      let params = {
        partialName: this.searchValue
      }

      let partialNameSize = params.partialName.length

      if (partialNameSize == 0)
        this.patientList = {}

      if (partialNameSize < 3)
        return

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.searchPatient(params)
        this.patientList = response.result
      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },

    async doSearchPatientsExams() {
      let params = {
        partialName: this.searchValue
      }

      let partialNameSize = params.partialName.length

      if (partialNameSize == 0)
        this.patientList = {}

      if (partialNameSize < 3)
        return

      this.loading = true
      NProgress.start()
      try {
        let response = await this.$patientService.searchPatientExams(params)
        this.patientList = response.result

      } catch (err) {
        this.$message.popup(this, 'request_error', 'operationFail', 'error')
        // this.alert = this.$message.alert(this, 'request_error', 'danger')
      }
      NProgress.done(true)
      this.loading = false
    },
    // async doSearchExam() {
    //
    // },
    toPatientDetails(item) {
      this.searchValue = ''
      this.patientList = {}

      //this.$router.push({name: 'patient-show', params: {patientID: item.id}, query: {tab: 'data'}})

      this.$router.push({name: 'patient-show', params: {patientID: item.id}, query: {tab: 'exams'}})

    },
    toExamDetails(event, item) {
      event.stopPropagation()
      let data = null
      this.searchValue = ''
      this.patientList = {}
      //this.$router.push({name: 'patient-show', params: {patientID: item.id}, query: {tab: 'data'}})

      this.$router.push({ name: 'exam-show', params: { examID: item.id }, query: { tab: 'images', data: data } })

    },
    toggleExamFilter() {
      this.isExamFilter = !this.isExamFilter
    },
    formatDate(date) {
      return moment(date).format(this.$i18n.t('dateTimeFormat'))
    },
    async openChangeOffcanvas() {
      const ok = await this.$refs.clinicChangeOffcanvas.show({})
      if (ok) {}
    },
  }
}
</script>
